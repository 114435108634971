import axios from 'axios';
const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

async function getApplicationNamespaces() {
  const data = await axios.get(BASE_API_URL + '/v2/application-namespace', {
      headers: {
          "Content-Type": "application/json"
      }
  });
  let applicationNamespaces = []
  let locationTypes = {}
  if(data.data.applicationNamespaces){
    data.data.applicationNamespaces.forEach(element => {
      if(element.is_active)
      {
        applicationNamespaces.push(element.name)
        locationTypes[element.name] = element.metadata.location_types || []
      }
    });
  }
  return {
    'applicationNamespaces' : applicationNamespaces,
    'locationTypes' : locationTypes,
    'defaultApplicationNamespace': data.data.defaultApplicationNamespace || (applicationNamespaces.length == 0 ? 'CARGOES' : applicationNamespaces[0])
  };
};

async function setApplicationNamespacesInLocal() {
  const {applicationNamespaces, locationTypes, defaultApplicationNamespace} = await getApplicationNamespaces();
  localStorage.setItem('_applicationNamespaces', JSON.stringify(applicationNamespaces));
  localStorage.setItem('_locationTypes', JSON.stringify(locationTypes));
  localStorage.setItem('_defaultApplicationNamespace', defaultApplicationNamespace);
}

export default setApplicationNamespacesInLocal;
