import React from 'react';
import { InputBase, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles/ManageLocationsStyles';

const SearchBar = ({ searchKey, handleSearch, clearSearchText, handleFilterButtonClick }) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setError(value.length > 0 && value.length < 3 );
    handleSearch(e);
  };

  const clearSearchTextWithError = () => {
    clearSearchText()
    setError(false)
  }
  
  return (
    <div style={{marginTop: '20px'  }}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search template' }}
        value={searchKey.value}
        onChange={handleInputChange}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          searchKey.value.length > 0 ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={clearSearchTextWithError}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
    <div style={{
      marginLeft: 10,
      color: error ? 'red' : 'transparent', // Show red text only when there's an error
      fontSize: '0.875rem',
      marginTop: 3,
    }}> {error ? 'Minimum Three Characters Required!' : 'No Text'}</div>
    </div>
  );
};

export default SearchBar;
