import { useState, useEffect } from 'react';
import LocationsService from '../../../services/LocationsService';
import { Grid, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import notify from '../../../utils/notifier';

const defaultApplicationNamespace = localStorage.getItem('_defaultApplicationNamespace')


// Export columns, default filters, and manageColumns as constants
export const addressColumns = [
  { id: 'id', label: 'Id', width: '10%' },
  { id: 'line1', label: 'Line 1', width: '10%'  },
  { id: 'firstName', label: 'First Name', width: '10%'  },
  { id: 'lastName', label: 'Last Name', width: '10%'  },
  { id: 'zipCode', label: 'Zip Code', width: '10%'  },
  { id: 'state', label: 'State', width: '10%'  },
  { id: 'city', label: 'City', width: '10%'  },
  { id: 'country', label: 'Country', width: '10%'  },
  { id: 'version', label: 'Version', width: '10%'  },
  { id: 'actions', label: 'Actions', width: '10%'  },
];

export const defaultFilters = {
  application_namespace: defaultApplicationNamespace,
  is_active: true
};

export const addressManageColumns = {
  id: true,
  line1: true,
  zipCode: true,
  state: false,
  firstName: false,
  lastName: false,
  city: false,
  country: true,
  version: false,
};

// Export the logic and state hooks for use in other components
export const Addresses = () => {
  const [addressesFilterValues, setAddressesFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);
  const applicationNamespaces = JSON.parse(localStorage.getItem('_applicationNamespaces'));
  

  // Function to apply the filters
  const getAddressFilters = async (filters) => {
    if(addressesFilterValues.application_namespace){
      filters['applicationNamespace'] = addressesFilterValues.application_namespace;
    }
    filters['_isActive'] = addressesFilterValues.is_active;
    return filters;
  };

  const defaultFiltersForQuery = {
    application_namespace: addressesFilterValues.application_namespace || 'Default',
    is_active: addressesFilterValues.is_active || 'true'
  };

  const handleAddressesFilterClear = (forQuerySearch=false) => {
    if(forQuerySearch){
      setAddressesFilterValues(defaultFiltersForQuery);
    }else{
      setAddressesFilterValues(defaultFilters);
    }
  };

  const handleApplicationNamespaceFilterChange = event => {
    const { name, value } = event.target;
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleActiveFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? 'true' : 'false';
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const downloadAddressesData = async query => {
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      if(query._format === undefined || !query._format){
        query._format = 'CSV'
      }

      const response = await LocationsService.downloadAddressesData(query);

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      
      const timestamp = new Date().toLocaleString('en-GB').replace(/[/,: ]/g, '-');
      const fileName = `addresses_${timestamp}.csv`;
      
      link.download = fileName; 
      link.click();
      setTimeout(() => {
        notify({
          type: 'success',
          message: 'File downloaded successfully.'
        });
      }, 50); 
    } catch (error) {
      console.error('Failed to download file:', error);
      notify({
        type: 'error',
        message: 'Something went wrong while downloading the file.'
      });
    }
  };

  // Function to fetch address data
  const fetchAddressesData = async query => {
    let output = {};
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }

      const response = await LocationsService.getAddressData(query);
      output.data = []
      response.data.forEach(response => {
        output.data.push({
          id : response.id,
          firstName: response.address.firstName,
          lastName: response.address.lastName,
          zipCode: response.address.zipCode,
          state: response.address.state,
          line1: response.address.line1,
          city: response.address.city,
          country: response.address.countryName,
          latitude: response.address.latitude,
          longitude: response.address.longitude,
          isActive: response.isActive,
          version: response.version,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt
        })
      })
      if (response.headers.hasOwnProperty('x-total-count')) {
        output['x-total-count'] = parseInt(response.headers['x-total-count']);
      }
    } catch (error) {
      notify({
        type: 'error',
        message: 'Internal Server Error'
      });
    }
    return output;
  };

  const addressesFilterConfig = [
    {
      name: 'is_active',
      label: 'Active',
      type: 'select',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
      ],
      filterChange: handleActiveFilterChange
    },
    {
      name: 'application_namespace',
      label: 'Application Namespace',
      type: 'select',
      options: applicationNamespaces,
      filterChange: handleApplicationNamespaceFilterChange
    }
  ];


  const renderAddressesFilters = () => {
    return addressesFilterConfig.map(filter => {
      const options = filter.options;
      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
            <InputLabel>{filter.label}</InputLabel>
            <Select
              name={filter.name}
              value={addressesFilterValues[filter.name]}
              onChange={filter.filterChange}
            >
              {options.map(option => (
                <MenuItem
                  key={option.value || option.name || option}
                  value={option.value || option.name || option}
                >
                  {option.label || option.name || option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    });
  };

  return {
    addressesFilterValues,
    setAddressesFilterValues,
    handleAddressesFilterClear,
    downloadAddressesData,
    getAddressFilters,
    fetchAddressesData,
    backdrop,
    setBackdrop,
    addressesFilterConfig,
    renderAddressesFilters
  };
};
