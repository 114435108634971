import { useState, useEffect } from 'react';
import LocationsService from '../../../services/LocationsService';
import notify from '../../../utils/notifier';
import { Grid, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

const defaultApplicationNamespace = localStorage.getItem('_defaultApplicationNamespace')

export const columns = [
  { id: 'id', label: 'Id', width: '10%' },
  { id: 'name', label: 'Name', width: '10%' },
  { id: 'type', label: 'Type', width: '10%'},
  { id: 'description', label: 'Description', width: '10%'},
  { id: 'actions', label: 'Actions', width: '10%' }
];

export const defaultFilters = {
  city: '',
  state: '',
  country: '',
  state_code: '',
  country_code: '',
  is_active: 'true',
  type:'',
  application_namespace: defaultApplicationNamespace
};

export const manageColumns = {
  id: true,
  name: true,
  type: true,
  description: false,
};

export const Zones = () => {
  const applicationNamespaces = JSON.parse(localStorage.getItem('_applicationNamespaces'));
  const [zonesFilterValues, setZonesFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  // Fetch countries data on mount
  useEffect(() => {
    if (!countries.length) {
      getCountriesData();
    }
  }, [countries]);

  // Fetch states and cities when the country filter changes
  useEffect(() => {
    if (zonesFilterValues.country) {
      getStatesData(zonesFilterValues.country);
      getCitiesData(zonesFilterValues.country);
    }
  }, [zonesFilterValues.country, countries]);

  // Fetch cities when the state filter changes
  useEffect(() => {
    if (zonesFilterValues.state) {
      getCitiesData(zonesFilterValues.country, zonesFilterValues.state);
    }
  }, [zonesFilterValues.state, states]);

  const defaultFiltersForQuery = {
    city: zonesFilterValues.city || '',
    state: zonesFilterValues.state || '',
    country: zonesFilterValues.country || '',
    state_code: zonesFilterValues.state_code || '',
    country_code: zonesFilterValues.country_code || '',
    is_active: zonesFilterValues.is_active || 'true',
    type: zonesFilterValues.type || '',
    application_namespace: zonesFilterValues.application_namespace || defaultApplicationNamespace
  };

  // Function to clear the filters
  const handleZonesFilterClear = (forQuerySearch=false) => {
    setStates([]);
    setCities([]);
    setStateCodes([]);
    if(forQuerySearch){
      setZonesFilterValues(defaultFiltersForQuery);
    }else{
      setZonesFilterValues(defaultFilters);
    }
  };

  // Function to apply the filters
  const getZoneFilters = async (filters) => {
    if (zonesFilterValues.city) {
      const city = cities.find(
        city =>
          city.name === zonesFilterValues.city &&
          city.countryIso2 === zonesFilterValues.country_code
      );
      filters['_cityId'] = city.id;
    } else if (zonesFilterValues.state) {
      const state = states.find(
        state =>
          state.name === zonesFilterValues.state &&
          state.countryIso2 === zonesFilterValues.country_code
      );
      filters['_stateId'] = state.id;
    } else if (zonesFilterValues.country) {
      const country = countries.find(
        country =>
          country.name === zonesFilterValues.country &&
          country.iso2 === zonesFilterValues.country_code
      );
      filters['_countryId'] = country.id;
    } 
    
    if (zonesFilterValues.type) {
      filters['_type'] = zonesFilterValues.type;
    } 

    if(zonesFilterValues.application_namespace){
      filters['_applicationNamespace'] = zonesFilterValues.application_namespace;
    }

    filters['_isActive'] = zonesFilterValues.is_active;
    return filters;
  };

  // Function to fetch countries
  const getCountriesData = async () => {
    try {
      setBackdrop(true);
      const response = await LocationsService.getCountriesData();
      const countriesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      const countryCodesData = countriesData.map(x => x.iso2).sort();
      setCountries(countriesData);
      setCountryCodes(countryCodesData);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching countries data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch states based on country
  const getStatesData = async countryName => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      setBackdrop(true);
      const response = await LocationsService.getStatesData(query);
      const statesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
    
      setStates(statesData);
      setStateCodes(statesData.map(x => x.stateIso2));
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching states data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch cities based on country and state
  const getCitiesData = async (countryName, stateName = '') => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      if (stateName) {
        const state = states.find(state => state.name === stateName);
        query['_stateIso2'] = state.stateIso2;
      }
      setBackdrop(true);
      const response = await LocationsService.getCitiesData(query);
      response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
      setCities(response.data);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching cities data' });
    } finally {
      setBackdrop(false);
    }
  };

  const handleCountryFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.name === value);
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country_code: country.iso2
    }));
    getStatesData(value);
    getCitiesData(value);
  };

  const handleStateFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.name === value);
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state_code: state.stateIso2 || ''
    }));
    if (!zonesFilterValues.city) {
      getCitiesData(zonesFilterValues.country, value);
    }
  };

  const handleCityFilterChange = event => {
    const { name, value } = event.target;
    if (!zonesFilterValues.state) {
      const selectedCity = cities.find(city => city.name === value);
      setZonesFilterValues(prevValues => ({
        ...prevValues,
        [name]: value,
        state: selectedCity ? selectedCity.stateName : prevValues.state,
        state_code: selectedCity ? selectedCity.stateIso2 : prevValues.state_code,
        country: selectedCity ? selectedCity.countryName : prevValues.country,
        country_code: selectedCity ? selectedCity.countryIso2 : prevValues.country_code
      }));
    } else {
      setZonesFilterValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleCountryCodeFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.iso2 === value);
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country: country.name || ''
    }));
    getStatesData(country.name);
    getCitiesData(country.name);
  };

  const handleApplicationNamespaceFilterChange = event => {
    const { name, value } = event.target;
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleStateCodeFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.stateIso2 === value);
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state: state.name || ''
    }));
    getCitiesData(zonesFilterValues.country, state.name);
  };

  const handleTypeFilterChange = event => {
    const { name, value } = event.target;
    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleActiveFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? 'true' : 'false';

    setZonesFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const downloadZonesData = async query => {
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      if(query._format === undefined || !query._format){
        query._format = 'CSV'
      }
      delete query._order;

      const response = await LocationsService.downloadZonesData(query);;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      
      const timestamp = new Date().toLocaleString('en-GB').replace(/[/,: ]/g, '-');
      const fileName = `zones_${timestamp}.csv`;
      
      link.download = fileName; 
      link.click();
      setTimeout(() => {
        notify({
          type: 'success',
          message: 'File downloaded successfully.'
        });
      }, 50); 
    } catch (error) {
      console.error('Failed to download file:', error);
      notify({
        type: 'error',
        message: 'Something went wrong while downloading the file.'
      });
    }
  };
  // Function to fetch zones data
  const fetchZonesData = async query => {
    let output = {};
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      delete query._order;

      const response = await LocationsService.getZonesData(query);
      output.data = []
      response.data.forEach(response => {
        output.data.push({
          id : response.zoneId,
          name: response.zoneDetails.name,
          description: response.zoneDetails.description,
          type: response.zoneDetails.type,
          isActive: response.zoneDetails.is_active,
          location_codes: response.zoneDetails.location_codes,
          zip_code_range: response.zoneDetails.zip_code_range,
          zip_codes: response.zoneDetails.zip_codes,
          node_codes: response.zoneDetails.node_codes,
          locodes: response.zoneDetails.locodes,
        })
      })
      if (response.headers.hasOwnProperty('x-total-count')) {
        output['x-total-count'] = parseInt(response.headers['x-total-count']);
      }
    } catch (error) {
      notify({
        type: 'error',
        message: 'Internal Server Error'
      });
    }
    return output;
  };

  const zonesFilterConfig = [
    {
      name: 'city',
      label: 'City',
      type: 'select',
      options: cities,
      filterChange: handleCityFilterChange
    },
    {
      name: 'state',
      label: 'State',
      type: 'select',
      options: states,
      filterChange: handleStateFilterChange
    },
    {
      name: 'country',
      label: 'Country',
      type: 'select',
      options: countries,
      filterChange: handleCountryFilterChange
    },
    {
      name: 'state_code',
      label: 'State Code',
      type: 'select',
      options: stateCodes,
      filterChange: handleStateCodeFilterChange
    },
    {
      name: 'country_code',
      label: 'Country Code',
      type: 'select',
      options: countryCodes,
      filterChange: handleCountryCodeFilterChange
    },
    {
      name: 'is_active',
      label: 'Active',
      type: 'select',
      options: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
      ],
      filterChange: handleActiveFilterChange
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: [
        { value: 'CUSTOM_ZIP_CODES', label: 'CUSTOM_ZIP_CODES' },
        { value: 'CUSTOM_LOCATION_CODES', label: 'CUSTOM_LOCATION_CODES' },
        { value: 'CUSTOM_LOCODES', label: 'CUSTOM_LOCODES' },
        { value: 'ZIP_CODE_RANGE', label: 'ZIP_CODE_RANGE' },
        { value: 'CUSTOM_BOUNDARY', label: 'CUSTOM_BOUNDARY' }
      ],
      filterChange: handleTypeFilterChange
    },
    {
      name: 'application_namespace',
      label: 'Application Namespace',
      type: 'select',
      options: applicationNamespaces,
      filterChange: handleApplicationNamespaceFilterChange
    }
  ];

  const renderZonesFilters = () => {

    return zonesFilterConfig.map(filter => {
      const options = filter.options;
      const isCountrySelected =
        !!zonesFilterValues.country_code || !!zonesFilterValues.country;

      // Determine if the current filter should be disabled
      const isDisabled =
        ((filter.name === 'state' || filter.name === 'state_code' || filter.name === 'city') &&
        !isCountrySelected) || (filter.name === 'type' && !zonesFilterValues.application_namespace);

      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
            <InputLabel>{filter.label}</InputLabel>
            <Select
              name={filter.name}
              value={zonesFilterValues[filter.name]}
              onChange={filter.filterChange}
              disabled={isDisabled}
            >
              {options.map(option => (
                <MenuItem
                  key={option.value || option.name || option}
                  value={option.value || option.name || option}
                >
                  {option.label || option.name || option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    });
  };

  return {
    zonesFilterValues,
    setZonesFilterValues,
    handleZonesFilterClear,
    getZoneFilters,
    getCountriesData,
    getStatesData,
    getCitiesData,
    fetchZonesData,
    downloadZonesData,
    backdrop,
    setBackdrop,
    zonesFilterConfig,
    renderZonesFilters
  };
};
