import React from 'react';
import dpWorldLogo from '../../images/Cargochain.png';
import documentAuthenticity from '../../images/documentAuthenticity.svg';
import immutableLogo from '../../images/traceability.svg';
import smartContractLogo from '../../images/automation.svg';
import ai from '../../images/machinelearning.svg';
import iot from '../../images/internet_of_things.svg';
import cryptography from '../../images/cryptography-lock.svg';
import costEffectiveLogo from '../../images/cost.svg';
import cloudAgnostic from '../../images/clouds.svg';
import easeOfUse from '../../images/ease.svg';
import scalability from '../../images/scalable.svg';
import security from '../../images/security.svg';
import performance from '../../images/performance.svg';
import { makeStyles, MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default function LoginPage(props) {
  const styles = makeStyles(theme => ({
    container: {
      backgroundColor: '#f5f5f5',
      padding: '10px',
      marginTop: '40px'
    },

    containertest: {
      backgroundColor: '#f5f5f5',
      padding: '10px',
      marginTop: '40px'
    },

    containerTechnical: {
      padding: '10px',
      marginTop: '20px',
      backgroundColor: 'white'
    },

    mainDiv: {
      textAlign: 'center',
      marginTop: '5%'
    },

    mainLogo: {
      height: 100
    },

    infoGrid: {
      margin: '0 auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      ['@media only screen and (max-width:600px)']: {
        display: 'block'
      }
    },

    infoGridTechnical: {
      margin: '0 auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      ['@media only screen and (max-width:600px)']: {
        display: 'block'
      }
    },

    gridContents: {
      flex: ' 0 0 30%',
      boxSizing: 'border-box',
      padding: '10px 10px 0',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ['@media only screen and (max-width:600px)']: {
        marginBottom: '15px'
      }
    },

    gridContentsTechnical: {
      flex: ' 0 0 30%',
      boxSizing: 'border-box',
      padding: '10px 10px 0',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ['@media only screen and (max-width:600px)']: {
        marginBottom: '15px'
      }
    },

    cloudAgnosticDiv: {
      flex: ' 0 0 30%',
      marginTop: '20px',
      boxSizing: 'border-box',
      padding: '10px 10px 0',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ['@media only screen and (max-width:600px)']: {
        marginBottom: '15px'
      }
    },

    gridContentTitle: {
      fontWeight: '500',
      fontSize: '1.6rem',
      margin: '2rem 0',
      lineHeight: '1.5'
    },

    gridContentDescription: {
      color: '#3a3a3a',
      fontSize: '1.2rem',
      lineHeight: '1.2'
    },

    gridContentDescriptionTechnical: {
      color: '#3a3a3a',
      fontSize: '1.2rem',
      lineHeight: '1.2'
    },

    gridLogos: {
      maxHeight: '5rem',
      maxWidth: '5rem',
      verticalAlign: 'top'
    },
    heading: {
      fontFamily: 'PilatWideHeavy',
      color: 'black',
      fontSize: '4.5rem'
    }
  }));
  const theme = useTheme();
  const classes = styles();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.mainDiv}>
        <Typography variant="h1" className={classes.heading}>
          <b>LOCATIONS SERVICE</b>
        </Typography>
        <div className={classes.container}>
          <h1 style={{ fontSize: '3em', color: '#298bc1' }}>
            <b>Solution Focus</b>
          </h1>

          <div className={classes.infoGrid}>
            <div className={classes.gridContents}>
              <img src={documentAuthenticity} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Multiple Delivery Channels</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Reach your audience everywhere with seamless multi-channel locations for
                effective communication with audit capability.
              </p>
            </div>

            <div className={classes.gridContents}>
              <img src={immutableLogo} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Improved Traceability</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Track, monitor, and trace every location with enhanced visibility for improved
                accountability and reliability.
              </p>
            </div>

            <div className={classes.gridContents}>
              <img src={smartContractLogo} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Operation Automation</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Experience seamless, automated communication at scale with our advanced location
                service empowered by intelligent chatbot capabilities.
              </p>
            </div>
          </div>
        </div>

        <div className={classes.containerTechnical}>
          <h1 style={{ fontSize: '3em', color: '#298bc1' }}>
            <b>Technical Focus</b>
          </h1>
          <div className={classes.infoGrid}>
            <div className={classes.gridContents}>
              <img src={ai} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Seamless Integration</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Effortlessly integrate locations into your systems with our seamless, scalable
                enterprise location service through APIs, webhooks and messaging infrastructure.
              </p>
            </div>

            <div className={classes.gridContents}>
              <img src={iot} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Inbuilt Fault tolerence</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Ensure uninterrupted communication to your customers with our resilient and
                fault-tolerant enterprise location service for reliable message delivery.
              </p>
            </div>

            <div className={classes.gridContents}>
              <img src={cryptography} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Asynchronous Processing</b>
              </h2>
              <p className={classes.gridContentDescription}>
                Unlock enhanced efficiency with asynchronous processing in our enterprise location
                service, enabling parallel and optimized message handling.
              </p>
            </div>
          </div>
        </div>

        <div className={classes.containertest}>
          <h1 style={{ fontSize: '3em', color: '#298bc1' }}>
            <b>Our Advantages</b>
          </h1>

          <div className={classes.infoGridTechnical}>
            <div className={classes.cloudAgnosticDiv}>
              <img src={cloudAgnostic} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Cloud Agnostic</b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                Provides flexiblity through seamless multi cloud integrations{' '}
              </p>
            </div>

            <div className={classes.gridContentsTechnical}>
              <img src={easeOfUse} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Ease Of Use </b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                Simple RESTful APIs as well as intuitive UI interfaces to access data{' '}
              </p>
            </div>
            <br></br>

            <div className={classes.gridContentsTechnical}>
              <img src={costEffectiveLogo} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Cost Effective </b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                We use virtualization technologies to reduce the number of servers
              </p>
            </div>
          </div>

          <div className={classes.infoGridTechnical}>
            <div className={classes.gridContentsTechnical}>
              <img src={security} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Security </b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                Required controls are in place to secure the data at rest and in transit{' '}
              </p>
            </div>

            <div className={classes.gridContentsTechnical}>
              <img src={scalability} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Highly Scalable</b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                Can easily scale up to support 1000 transactions per second{' '}
              </p>
            </div>

            <div className={classes.gridContentsTechnical}>
              <img src={performance} alt="DPW" className={classes.gridLogos} />
              <h2 className={classes.gridContentTitle}>
                <b>Performance </b>
              </h2>
              <p className={classes.gridContentDescriptionTechnical}>
                We ensure that the transactions complete with minimum latency{' '}
              </p>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </MuiThemeProvider>
  );
}
